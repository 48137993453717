import React, { useState, useEffect, useContext } from "react";
import { search, fondo } from '../providers';
import Header from "../modules/Header";
import userEvent from "@testing-library/user-event";
import { UserContext } from "../context/UserContext";


function FacturasTB() {
    const [user, setUser] = useContext(UserContext);
    const [asuntos, setAsuntos] = useState([]);
    const [id, setId] = useState();

    const [nombre, setNombre] = useState("");
    const [nombreAsunto, setNombreAsunto] = useState("");
    const [codigo, setCodigo] = useState("");
    const [activo, setActivo] = useState(false);
    const [cobrable, setCobrable] = useState(false);
    const [IDconvenio, setIDconvenio] = useState("");
    const [NroAsunto, setNroAsunto] = useState("");
    const [dateFrom, setDateFrom] = useState();
    const [dateTo, setDateTo] = useState();

    const [monto, setMonto] = useState();
    const [montosAll, setMontosAll] = useState([]);

    let montos = [];
    let montosTotal=0;

    const getProjects = async () => {
        await fetch("https://app-mcewan.herokuapp.com/timebilling/getProjects", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "token": user.tokenTimeBilling
            })
        })
            .then(response => response.json())
            .then((value) => {
                console.log(value);
                setAsuntos(value);
            })
            .catch(error => console.log(error));
    }

    useEffect(() => {
        (async () => {
            await getProjects();
        })();
    }, [user.tokenTimeBilling]);

    const filterById = async (e) => {
        e.preventDefault();
        await fetch("https://app-mcewan.herokuapp.com/timebilling/getProjects/" + id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "token": user.tokenTimeBilling
            })
        })
            .then(response => response.json())
            .then((value) => {
                const arr = [];
                console.log(value);
                arr.push(value);
                setAsuntos(arr);
            })
            .catch(error => console.log(error));
        setId("");
    }

    const getPayments = async (idP) => {
        let valuereturn;
        await fetch("https://app-mcewan.herokuapp.com/timebilling/getPayments/" + idP, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "token": user.tokenTimeBilling
            })
        })
            .then(response => response.json())
            .then((value) => {
                setMonto(value);
                console.log(value);
                valuereturn = value;
            })
            .catch(error => console.log(error));
        return valuereturn;
    }

    const getAllMontos = (e) => {
        e.preventDefault();
        asuntos.filter((val) => {
            if (nombre === "") {
                return val;
            } else if (val.client_name.toLowerCase().includes(nombre.toLowerCase())) {
                return val;
            }
        }).filter((val) => {
            if (nombreAsunto === "") {
                return val;
            } else if (val.name.toLowerCase().includes(nombreAsunto.toLowerCase())) {
                return val;
            }
        }).filter((val) => {
            if (codigo === "") {
                return val;
            } else if (val.client_code.toLowerCase().includes(codigo.toLowerCase())) {
                return val;
            }
        }).filter((val) => {
            if (NroAsunto === "") {
                return val;
            } else if (val.code.toLowerCase().includes(NroAsunto.toLowerCase())) {
                return val;
            }
        }).filter((val) => {
            if (IDconvenio === "") {
                return val;
            } else if (IDconvenio === val.agreement_id) {
                return val;
            }
        }).filter((val) => {
            if (activo === false) {
                return val;
            } else if (val.active === "1" && activo === true) {
                return val;
            }
        }).filter((val) => {
            if (cobrable === false) {
                return val;
            } else if (val.collectable === "1" && cobrable === true) {
                return val;
            }
        }).filter((val) => {
            let dateCheck;
            let tsDate;
            let tsDateTo;
            tsDateTo = Date.parse(dateTo);
            tsDate = Date.parse(dateFrom);
            dateCheck = Date.parse(val.created_at)
            if (dateFrom === undefined || dateTo === undefined) {
                return val;
            } else if (dateCheck >= tsDate && dateCheck <= tsDateTo) {
                return val;
            }
        }).map((value, index) => {
            getPayments(value.id).then((value) => {
                if (value.length > 0) {
                    montos.push(value);
                }

            })
        })

        setMontosAll(montos);
        console.log(montosAll);
    }




    return (
        <div className="container_general">

            <img src={fondo} className='fondo' alt='mcewanfondo' />
            <Header />
            <div className="body_container">
                <div className="general-filters">
                    <form>

                        <div className="form-group">
                            <p>Nombre Cliente</p>
                            <div className="input-case">
                                <input type="text" className="search_input"
                                    value={nombre} onChange={(value) => setNombre(value.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <p>Nombre Asunto</p>
                            <div className="input-case">
                                <input type="text" className="search_input"
                                    value={nombreAsunto} onChange={(value) => setNombreAsunto(value.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <p>Codigo Cliente</p>
                            <div className="input-case">
                                <input type="text" className="search_input"
                                    value={codigo} onChange={(value) => setCodigo(value.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <p>Nro de Convenio</p>
                            <div className="input-case">
                                <input type="text" className="search_input"
                                    value={IDconvenio} onChange={(value) => setIDconvenio(value.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <p>Nro de Asunto</p>
                            <div className="input-case">
                                <input type="text" className="search_input"
                                    value={NroAsunto} onChange={(value) => setNroAsunto(value.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <p>Solo Activos</p>
                            <div className="input-case">
                                <input type="checkbox" className="search_input"
                                    value={activo} onChange={(value) => setActivo(value.target.checked)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <p>Solo Cobrables</p>
                            <div className="input-case">
                                <input type="checkbox" className="search_input"
                                    value={cobrable} onChange={(value) => setCobrable(value.target.checked)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <p>Fecha Desde</p>
                            <div style={{ width: '32%' }} className="input-case">
                                <input type="date" className="search_input"
                                    value={dateFrom} onChange={(value) => setDateFrom(value.target.value)}
                                />

                            </div>
                            <p>Fecha Hasta</p>
                            <div style={{ width: '32%' }} className="input-case">
                                <input type="date" className="search_input"
                                    value={dateTo} onChange={(value) => setDateTo(value.target.value)}
                                />

                            </div>
                        </div>
                    </form>

                    {(() => {
                        if (nombre !== "" || codigo !== "" || NroAsunto !== "" ||
                            IDconvenio !== "" || dateFrom !== undefined || dateTo !== undefined) {
                            return (
                                <form className={`report-case`} onSubmit={getAllMontos}>
                                    <button>Sumar montos</button>
                                </form>
                            )
                        }
                    })()}


                </div>
                <div className="filters_container">
                    <form className="search_container" onSubmit={filterById}>
                        <input type="text" placeholder="Ingrese la id del asunto" className="search_input"
                            value={id} onChange={(value) => setId(value.target.value)}
                        />
                        <button className="search_button">
                            <img src={search} className="search_img" />
                        </button>
                    </form>
                    <button className={`return_button ${asuntos.length === 1 ? "" : "no_display"}`}
                        onClick={getProjects}>
                        Volver
                    </button>
                </div>
                {
                    montosAll.length > 0 ? (
                        <div className="table_card">
                            <table>
                                <tr>
                                    <th>id</th>
                                    <th>nombre</th>
                                    <th>monto</th>
                                </tr>
                                {
                                    montosAll.map((value, index) => {
                                        return value.map((value, index) => {
                                            montosTotal=montosTotal+parseFloat(value.amount);
                                            return (
                                                <tr key={index}>
                                                    <td>{value.project_id}</td>
                                                    <td>{value.name}</td>
                                                    <td>{value.amount}</td>
                                                </tr>
                                            );
                                        })
                                    })
                                }
                                
                            </table>
                            <p style={{textAlign:"center"}}>Total: {montosTotal}</p>
                        </div>
                    ) : null
                }

                <div className="table_card">
                    <table>
                        <tr>
                            <th>ID</th>
                            <th>Nro Asunto</th>
                            <th>Nombre</th>
                            <th>Convenio ID</th>
                            <th>Código de cliente</th>
                            {/*<th>Id del cliente</th>*/}
                            <th>Nombre del cliente</th>

                            <th>Creado en</th>
                            <th>Moneda</th>
                            <th>Activo</th>
                            <th>Cobrable</th>
                            {/*<th>Id de proyecto</th>
                            <th>Id de sub área</th>
                            <th>Id de tipo de projecto</th>*/}

                        </tr>
                        {

                            asuntos.filter((val) => {
                                if (nombre === "") {
                                    return val;
                                } else if (val.client_name.toLowerCase().includes(nombre.toLowerCase())) {
                                    return val;
                                }
                            }
                            ).filter((val) => {
                                if (nombreAsunto === "") {
                                    return val;
                                } else if (val.name.toLowerCase().includes(nombreAsunto.toLowerCase())) {
                                    return val;
                                }
                            }
                            ).filter((val) => {
                                if (codigo === "") {
                                    return val;
                                } else if (val.client_code.toLowerCase().includes(codigo.toLowerCase())) {
                                    return val;
                                }
                            }).filter((val) => {
                                if (NroAsunto === "") {
                                    return val;
                                } else if (val.code.toLowerCase().includes(NroAsunto.toLowerCase())) {
                                    return val;
                                }
                            }).filter((val) => {
                                if (IDconvenio === "") {
                                    return val;
                                } else if (IDconvenio === val.agreement_id) {
                                    return val;
                                }
                            }).filter((val) => {
                                if (activo === false) {
                                    return val;
                                } else if (val.active === "1" && activo === true) {
                                    return val;
                                }
                            }).filter((val) => {
                                if (cobrable === false) {
                                    return val;
                                } else if (val.collectable === "1" && cobrable === true) {
                                    return val;
                                }
                            }).filter((val) => {
                                let dateCheck;
                                let tsDate;
                                let tsDateTo;
                                tsDateTo = Date.parse(dateTo);
                                tsDate = Date.parse(dateFrom);
                                dateCheck = Date.parse(val.created_at)
                                if (dateFrom === undefined || dateTo === undefined) {
                                    return val;
                                } else if (dateCheck >= tsDate && dateCheck <= tsDateTo) {
                                    return val;
                                }
                            }).map((value, index) => {
                                var activoState, cobrableState;
                                value.active === '1' ? activoState = 'Si' : activoState = 'No'
                                value.collectable === '1' ? cobrableState = 'Si' : cobrableState = 'No'

                                return (
                                    <tr key={index}>
                                        <td>{value.id}</td>
                                        <td>{value.code}</td>
                                        <td>{value.name}</td>
                                        <td>{value.agreement_id}</td>
                                        <td>{value.client_code}</td>
                                        {/*<td>{value.client_id}</td>*/}
                                        <td>{value.client_name}</td>

                                        <td>{value.created_at}</td>
                                        <td>{value.currency_code}</td>
                                        <td>{activoState}</td>
                                        <td>{cobrableState}</td>

                                        {/*<td>{value.project_area_id}</td>
                                        <td>{value.project_sub_area_id}</td>
                                <td>{value.project_type_id}</td>*/}
                                    </tr>
                                );
                            })
                        }
                    </table>
                </div>
            </div>
        </div>
    );
}

export default FacturasTB;