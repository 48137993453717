import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from "../context/UserContext";


function ModalDash({ visible }) {

    const [user, _] = useContext(UserContext);

    const [searchState, setSearchState] = useState(false);
    const [filterState, setFilterState] = useState(false);
    const [paymentState, setPaymentState] = useState(false);



    const [nombre, setNombre] = useState("");
    const [Ventas, setVentas] = useState([]);
    const [asuntos, setAsuntos] = useState([]);
    const [fillAsuntos, setFillAsuntos] = useState([]);
    const [asunto, setAsunto] = useState([]);
    const [pago, setPago] = useState([]);
    const [id, setId] = useState(0);




    const [Clientes, setClientes] = useState([]);



    const getProjects = async () => {
        await fetch("https://app-mcewan.herokuapp.com/timebilling/getProjects", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "token": user.tokenTimeBilling
            })
        })
            .then(response => response.json())
            .then((value) => {
                console.log(value);
                setAsuntos(value);
            })
            .catch(error => console.log(error));
    }
    const getClientes = async () => {
        await fetch("https://app-mcewan.herokuapp.com/timebilling/getClients", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "token": user.tokenTimeBilling
            })
        })
            .then(response => response.json())
            .then((value) => {
                console.log(value);
                setClientes(value);
            })
            .catch(error => console.log(error));
    }



    const getFacturaVenta = async () => {
        await fetch("https://app-mcewan.herokuapp.com/xubio/getFacturaVenta",
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "access_token": user.tokenXubio
                })
            })
            .then(response => response.json())
            .then((value) => setVentas(value))
            .catch(error => console.log(error));
    }

    const getPayments = async () => {
        await fetch("https://app-mcewan.herokuapp.com/timebilling/getPayments/" + id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "token": user.tokenTimeBilling
            })
        })
            .then(response => response.json())
            .then((value) => {
                setPago(value)
                console.log(value);
            })
            .catch(error => console.log(error));
    }

    useEffect(() => {
        (async () => {
            await getProjects();
            await getFacturaVenta();
            await getClientes();
        })();
    }, [user]);


    const getCliente = (value) => {
        var newName = value.name.replace(" FAMILIA", "");
        setNombre(newName);
        setFilterState(true);
        let asuntosFiltro = asuntos.filter((value) => {
            if (nombre === "") {
                return value;
            } else if (value.client_name.toLowerCase().includes(nombre.toLowerCase())) {
                return value;
            }
        });
        setFillAsuntos(asuntosFiltro);
    }
    const filterById = async () => {
        await fetch("https://app-mcewan.herokuapp.com/timebilling/getProjects/" + id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "token": user.tokenTimeBilling
            })
        })
            .then(response => response.json())
            .then((value) => {
                const arr = [];
                console.log(value);
                arr.push(value);
                setAsunto(arr);
            })
            .catch(error => console.log(error));
    }

    return (
        <div onClick={() => visible ? visible = false : null} className={visible ? "modal" : "nmodal"}>
            <div className='prime-modal'>
                <div className='input-case'>
                    <p>Nombre</p>
                    <input type="text" className="search_input"
                        value={nombre} onChange={(value) => setNombre(value.target.value)}
                    />
                    <button onClick={() => setSearchState(true)}>Buscar</button>
                </div>

                <div className="table_card">
                    <table>
                        <tr>
                            <th>ID</th>
                            <th>Activo</th>
                            <th>Creado en</th>
                            <th>Nombre</th>
                        </tr>
                        {
                            searchState === true ? (
                                Clientes.filter((value) => {
                                    if (nombre === "") {
                                        return value;
                                    } else if (value.name.toLowerCase().includes(nombre.toLowerCase())) {
                                        return value;
                                    }
                                }).map((value, index) => {
                                    return (
                                        <tr key={index} onClick={() => { getCliente(value) }}>
                                            <td>{value.id}</td>
                                            <td>{value.active}</td>
                                            <td>{value.created_at}</td>
                                            <td>{value.name}</td>
                                        </tr>
                                    );
                                })
                            ) : null
                        }
                    </table>
                </div>

            </div>
            <div className='modal-case'>

                <div className='submodal'>
                    {
                        paymentState === true ? (
                            <div className="table_card">
                                <table>
                                    <tr>

                                        <th>Nro Asunto</th>
                                        <th>Nombre</th>
                                        <th>Nombre del cliente</th>
                                        <th>Monto</th>
                                    </tr>
                                    {asunto.length > 0 ? (
                                        asunto.map((value, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{value.agreement_id}</td>
                                                    <td>{value.name}</td>
                                                    <td>{value.client_name}</td>
                                                    {pago.map((value) => {
                                                        return (
                                                            <td>{value.amount}</td>
                                                        )
                                                    })}

                                                </tr>

                                            )
                                        })
                                    ) : null
                                    }


                                </table>
                            </div>
                        ) : (<div>{id}</div>)
                    }
                    <div className="table_card">
                        <table>
                            <tr>
                                <th>Nro Asunto</th>
                                <th>Nombre</th>
                                <th>Convenio ID</th>
                                <th>Nombre del cliente</th>
                                <th>Creado en</th>
                                <th>Moneda</th>
                            </tr>
                            {
                                filterState === true ? (
                                    asuntos.filter((value) => {
                                        if (nombre === "") {
                                            return value;
                                        } else if (value.client_name.toLowerCase().includes(nombre.toLowerCase())) {
                                            return value;
                                        }
                                    }).map((value, index) => {

                                        return (
                                            <tr key={index} onClick={() => {
                                                setId(parseInt(value.id));
                                                getPayments();
                                                filterById();
                                                setPaymentState(true)
                                            }}>
                                                <td>{value.code}</td>
                                                <td>{value.name}</td>
                                                <td>{value.agreement_id}</td>
                                                <td>{value.client_name}</td>
                                                <td>{value.created_at}</td>
                                                <td>{value.currency_code}</td>

                                            </tr>
                                        );
                                    })
                                ) : null
                            }
                        </table>
                    </div>

                </div>
                <div className='submodal'>
                    xubio
                </div>
            </div>

        </div>
    );
}

export default ModalDash;