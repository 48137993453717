import React, { useState, useEffect, useContext } from "react";
import { search, fondo } from '../providers';
import Header from "../modules/Header";
import { UserContext } from "../context/UserContext";



function AsientoContable() {
    const [user, _] = useContext(UserContext);
    const [asientos, setAsientos] = useState([]);
    const [asientosAux, setAsientosAux] = useState([]);
    const [id, setId] = useState();
    const [tableState, setTableState] = useState("plural");

    const handleAux = () => {
        setAsientosAux(asientosAux);
    }

    const getAsientos = async () => {
        setTableState("plural");
        await fetch("https://app-mcewan.herokuapp.com/xubio/asientoContableManual",
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "access_token": user.tokenXubio
                })
            })
            .then(response => response.json())
            .then((value) => {
                console.log(value);
                setAsientos(value);
                setAsientosAux(value);
            })
            .catch(error => console.log(error));
    }

    useEffect(() => {
        (async () => {
            await getAsientos();
        })();
    }, [user.tokenXubio]);

    const filterById = async (e) => {
        e.preventDefault();
        setTableState("singular");

        await fetch("https://app-mcewan.herokuapp.com/xubio/asientoContableManual/" + id,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "access_token": user.tokenXubio
                })
            })
            .then(response => response.json())
            .then((value) => {
                const arr = [];
                console.log(value);
                arr.push(value);
                setAsientos(arr);
                console.log(arr);
            })
            .catch(error => console.log(error))
        setId("");
    }

    return (
        <div className="container_general">
            <img src={fondo} className='fondo' alt='mcewanfondo' />
            <Header />
            <div className="body_container">
                <div className="filters_container">
                    <form className="search_container" onSubmit={filterById}>
                        <input type="text" placeholder="Ingrese la id del asiento" className="search_input"
                            value={id} onChange={(value) => setId(value.target.value)}
                        />
                        <button className="search_button">
                            <img src={search} className="search_img" />
                        </button>
                    </form>
                    <button className={`return_button ${asientos.length === 1 ? "" : "no_display"}`} onClick={handleAux}>
                        Volver
                    </button>
                </div>
                <div style={{ width: 'auto' }} className="table_card">
                    <table>
                        <tr>
                            <th>Id de transacción</th>
                            <th>Número de documento</th>
                            <th>Tipo de asiento</th>
                            <th>Nombre</th>
                            <th>Fecha</th>
                        </tr>
                        {
                            asientos.length > 0 ? (
                                asientos.map((value, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{value.transaccionid}</td>
                                            <td>{value.numeroDocumento}</td>
                                            <td>{value.tipoAsiento}</td>
                                            <td>{value.nombre}</td>
                                            <td>{value.fecha}</td>
                                        </tr>
                                    );
                                })
                            ) : null
                        }
                    </table>
                    )
                </div>
            </div>
        </div>
    );
}

export default AsientoContable;