import React, { useEffect, useState } from 'react';
import testingAccess from '../providers/testinAccess.json';

function TestScreen() {
    const [token, setToken] = useState("");
    const [tbToken, setTBToken] = useState("");
    const [response, setResponse] = useState("");
    const [id, setId] = useState("");
    const [clients, setClients] = useState([]);

    useEffect(() => {
        (async () => {
            await getToken();
            await getTokenTimeBilling();
        })();
    }, []);
    const getClients = async () => {
        await fetch("https://app-mcewan.herokuapp.com/xubio/getClientes",
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "access_token": token
                })
            })
            .then(response => response.json())
            .then((value) => setClients(value))
            .catch(error => console.log(error))
    }

    const getClientXubio = async () => {
        await fetch("https://app-mcewan.herokuapp.com/xubio/getClientes/" + id,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "access_token": token
                })
            })
            .then(response => response.json())
            .then((value) => {
                setResponse(JSON.stringify(value));
                console.log(value);
            })
            .catch(error => console.log(error))
    }

    const getToken = async () => {
        await fetch("https://app-mcewan.herokuapp.com/xubio/getToken",
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "client_id": testingAccess.client_id,
                    "secret_id": testingAccess.secret_id
                })
            })
            .then(response => response.json())
            .then((value) => setToken(value.access_token))
            .catch(error => console.log(error));
    }

    const getFacturaCompra = async () => {
        await fetch("https://app-mcewan.herokuapp.com/xubio/getFacturaCompra",
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "access_token": token
                })
            })
            .then(response => response.json())
            .then((value) => setResponse(JSON.stringify(value)))
            .catch(error => console.log(error));
    }

    const getFacturaVenta = async () => {
        await fetch("https://app-mcewan.herokuapp.com/xubio/getFacturaVenta",
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "access_token": token
                })
            })
            .then(response => response.json())
            .then((value) => setResponse(JSON.stringify(value)))
            .catch(error => console.log(error));
    }

    const getTokenTimeBilling = async () => {
        await fetch("https://app-mcewan.herokuapp.com/timebilling/getToken", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "user": "sbeloqui@estudiomcewan.com.ar",
                "password": "Malena2019#",
                "app_key": "mcewan"
            })
        })
            .then(response => response.json())
            .then((value) => setTBToken(value.auth_token))
            .catch(error => console.log(error));
    }

    const getUsersTB = async () => {
        await fetch("https://app-mcewan.herokuapp.com/timebilling/getUsers", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "token": tbToken
            })
        })
            .then(response => response.json())
            .then((value) => {
                setResponse(JSON.stringify(value))
                console.log(value);
            })
            .catch(error => console.log(error));
    }

    const getUserTB = async () => {
        await fetch("https://app-mcewan.herokuapp.com/timebilling/getUser/" + id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "token": tbToken
            })
        })
            .then(response => response.json())
            .then((value) => {
                setResponse(JSON.stringify(value))
                console.log(value);
            })
            .catch(error => console.log(error));
    }

    const getProjects = async () => {
        await fetch("https://app-mcewan.herokuapp.com/timebilling/getProjects", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "token": tbToken
            })
        })
            .then(response => response.json())
            .then((value) => {
                setResponse(JSON.stringify(value))
                console.log(value);
            })
            .catch(error => console.log(error));
    }

    const getProject = async () => {
        await fetch("https://app-mcewan.herokuapp.com/timebilling/getProjects/" + id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "token": tbToken
            })
        })
            .then(response => response.json())
            .then((value) => {
                setResponse(JSON.stringify(value))
                console.log(value);
            })
            .catch(error => console.log(error));
    }

    const getPayments = async () => {
        await fetch("https://app-mcewan.herokuapp.com/timebilling/getPayments/" + id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "token": tbToken
            })
        })
            .then(response => response.json())
            .then((value) => {
                setResponse(JSON.stringify(value))
                console.log(value);
            })
            .catch(error => console.log(error));
    }

    return (
        <div>
            <input type="text" placeholder="id" onChange={(value) => {
                setId(value.target.value);
                console.log(value.target.value);
            }} />
            <button onClick={getFacturaCompra}>get Factura compra</button>
            <button onClick={getFacturaVenta}>get Factura venta</button>
            <button onClick={getClients}>get Clientes</button>
            <button onClick={getClientXubio}>get Cliente</button>
            <button onClick={getUsersTB}>get Users timebilling</button>
            <button onClick={getUserTB}>get User timebilling</button>
            <button onClick={getProjects}>get Asuntos</button>
            <button onClick={getProject}>get Asunto</button>
            <button onClick={getPayments}>get Pagos</button>
            <p>{token}</p>
            <p>{"\n" + response}</p>
            <table>
                <tr>
                    <th>Client ID</th>
                    <th>Name</th>
                </tr>
                {
                    clients.map(value => {
                        return (
                            <tr>
                                <td>{value.cliente_id}</td>
                                <td>{value.nombre}</td>
                            </tr>
                        )
                    })
                }
            </table>
        </div>
    );
}

export default TestScreen;