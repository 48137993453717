import React, { useState } from 'react';
import { favicom, fondo, iconTrash,add } from '../providers';

function EditRoles() {
    const [isLoading, setLoading] = useState(false);
    const [rol, setRol] = useState('');
   // const [roles,setroles]=useState([]);

    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            setLoading(!isLoading);
            setTimeout(() => {
                setLoading(false);
            }, 1500);
        }
        catch (error) {
            alert(error);
        }
    }

    return (
        <div className="container_general">
            <img src={fondo} className='fondo' alt='mcewanfondo' />

            <div className="roles_card">
                <div className='roles-top-part'>
                    <div>
                        <div className="roles_title">
                            <div className="top-letters">
                                <img className="icon-login" src={favicom} alt='mcewanlogo' />
                                <h3>ROLES</h3>
                            </div>
                            <div className="aux-line-roles" />
                        </div>
                    </div>
                    <button>X</button>
                </div>
                <div className='roles-middle-part'>
                    <div className='module'>
                        <div className='module-prop'>
                            <p>Administrador</p>
                        </div>
                        <img src={iconTrash} alt='mcewantrash' />
                    </div>
                    <div className='module'>
                        <div className='module-prop'>
                            <p>Editor</p>
                        </div>
                        <img src={iconTrash} alt='mcewantrash' />
                    </div>
                </div>
                <div className='roles-bottom-part'>
                    <form className='add-module' onSubmit={handleSubmit}>
                            <input value={rol} className='module-prop' placeholder='Nuevo rol'
                            onChange={(rol) => setRol(rol.target.value)}/>
                        <button><img src={add} alt='mcewanadd' /></button>
                    </form>
                </div>

            </div>
        </div>
    );
}

export default EditRoles;