import React, { useEffect, useState } from "react";
import { Navigate } from 'react-router-dom';

function Loading() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            if (loading) setLoading(false);
        }, 1500);
    }, []);
    return (
        <div className="loading_container">
            {
                !loading ? <Navigate to="/login" /> : null
            }
        </div>
    );
}

export default Loading;