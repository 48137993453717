import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { ResponsiveContainer } from "recharts";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function BarGraph() {
  const data = {
    labels: [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic'],
    datasets: [{
      label: 'Importe gravado',
      data: [65, 59, 70, 81, 56, 55, 40, 59, 70, 81, 66, 69],
      backgroundColor: [
        '#d42e12',
        '#004557',
        '#6d6d8d',
        '#d42e12',
        '#6d6d8d',
        '#004557',
        '#d42e12'
      ],
      borderColor: [
        '#d42e12',
        '#004557',
        '#6d6d8d',
        '#d42e12',
        '#6d6d8d',
        '#004557',
        '#d42e12'
      ],
      borderWidth: 1
    }, {
      label: 'Importe total',
      data: [65, 59, 70, 81, 56, 55, 40, 59, 70, 81, 66, 69],
      backgroundColor: [
        '#d42e12',
        '#004557',
        '#6d6d8d',
        '#d42e12',
        '#6d6d8d',
        '#004557',
        '#d42e12'
      ],
      borderColor: [
        '#d42e12',
        '#004557',
        '#6d6d8d',
        '#d42e12',
        '#6d6d8d',
        '#004557',
        '#d42e12'
      ],
      borderWidth: 1
    }]
  };
  return (
    <div className="graph-overlay">
      <div className="top-part">
        <p>INFORME X2</p>
      </div>
      <div className="bottom-part">
        <Bar data={data}
          options={{
            responsive: true,
            scales: {
              yAxes: [
                {
                  ticks: {
                    autoSkip: true,
                    maxTicksLimit: 15,
                    beginAtZero: true
                  }
                }
              ],
              xAxes: [
                {
                  gridLines: {
                    display: false
                  }
                }
              ]
            }
          }} />
      </div>

    </div>
  );
}

export default BarGraph;