import React, { useState, useEffect, useContext } from "react";
import { search, fondo } from '../providers';
import Header from "../modules/Header";
import testingAccess from '../providers/testinAccess.json';
import { UserContext } from "../context/UserContext";



function ClientesX() {
    const [user, _] = useContext(UserContext);
    const [Clientes, setClientes] = useState([]);
    const [Cliente, setCliente] = useState([]);
    const [id, setId] = useState(0);
    const [tableState, setTableState] = useState(false);

    const [nombre, setNombre] = useState("");
    const [nro, setNro] = useState("");


    const getClientes = async () => {
        await fetch("https://app-mcewan.herokuapp.com/xubio/getClientes",
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "access_token": user.tokenXubio
                })
            })
            .then(response => response.json())
            .then((value) => setClientes(value))
            .catch(error => console.log(error));
    }

    useEffect(() => {
        (async () => {
            await getClientes();
        })();
    }, [user.tokenXubio]);

    const filterById = async () => {
        setTableState(true);

        await fetch("https://app-mcewan.herokuapp.com/xubio/getClientes/" + id,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "access_token": user.tokenXubio
                })
            })
            .then(response => response.json())
            .then((value) => {
                const arr = [];
                console.log(value);
                arr.push(value);
                setCliente(arr);
            })
            .catch(error => console.log(error))
    }

    return (
        <div className="container_general">
            <img src={fondo} className='fondo' alt='mcewanfondo' />
            <Header />
            <div className="body_container">
                <div className="general-filters">
                    <form>
                        <div className="form-group">
                            <p>Nro Cliente</p>
                            <div className="input-case">
                                <input type="text" className="search_input"
                                    value={nro} onChange={(value) => setNro(value.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <p>Nombre Cliente</p>
                            <div className="input-case">
                                <input type="text" className="search_input"
                                    value={nombre} onChange={(value) => setNombre(value.target.value)}
                                />
                            </div>
                        </div>

                    </form>
                </div>
                {/*<div className="filters_container">
                    <form className="search_container" onSubmit={filterById}>
                        <input type="text" placeholder="Ingrese la id del usuario" className="search_input"
                            value={id} onChange={(value) => setId(value.target.value)}
                        />
                        <button className="search_button">
                            <img src={search} className="search_img" />
                        </button>
                    </form>
                    <button className={`return_button ${Clientes.length === 1 ? "" : "no_display"}`} onClick={getClientes}>
                        Volver
                    </button>
                </div>*/ }


                {
                    tableState === true ? (
                        <div style={{ width: 'auto', marginBottom: 30 }} className="table_card">
                            <table>
                                <tr>
                                    <th>ID</th>
                                    <th>Nombre</th>
                                    <th>CUIT</th>
                                    <th>Dirección</th>

                                </tr>
                                {
                                    Cliente.length > 0 ? (
                                        Cliente.map((value, index) => {
                                            return (
                                                <tr key={index} >
                                                    <td>{value.cliente_id}</td>
                                                    <td>{value.nombre}</td>
                                                    <td>{value.CUIT}</td>
                                                    <td>{value.direccion}</td>

                                                </tr>
                                            );
                                        })
                                    ) : null
                                }
                            </table>
                        </div>
                    ) : <div style={{ margin: 49 }} />
                }

                <div style={{ width: 'auto' }} className="table_card">
                    <table>
                        <tr>
                            <th>Nro</th>
                            <th>Nombre</th>
                        </tr>
                        {console.log(Clientes),
                            Clientes.length > 0 ? (
                                Clientes.filter((val) => {
                                    if (nombre === "") {
                                        return val;
                                    } else if (val.nombre.toLowerCase().includes(nombre.toLowerCase())) {
                                        return val;
                                    }

                                }
                                ).filter((val) => {
                                    var num = val.cliente_id.toString();
                                    if (nro === "") {
                                        return val;
                                    } else if (num.toLowerCase().includes(nro.toLowerCase())) {
                                        return val;
                                    }
                                }).map((value, index) => {
                                    return (
                                        <tr key={index} onClick={() => { setId(parseInt(value.cliente_id)); filterById() }}>
                                            <td>{value.cliente_id}</td>
                                            <td>{value.nombre}</td>
                                        </tr>
                                    );
                                })
                            ) : null
                        }
                    </table>
                </div>
            </div>
        </div>
    );
}

export default ClientesX;