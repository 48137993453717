import React, { useState } from 'react';
import { favicom, fondo, iconTrash,add } from '../providers';

function EditPermisos() {
    const [isLoading, setLoading] = useState(false);
    const [permiso, setPermiso] = useState('');
   // const [permisos,setPermisos]=useState([]);

    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            setLoading(!isLoading);
            setTimeout(() => {
                setLoading(false);
            }, 1500);
        }
        catch (error) {
            alert(error);
        }
    }

    return (
        <div className="container_general">
            <img src={fondo} className='fondo' alt='mcewanfondo' />

            <div className="permisos_card">
                <div className='permisos-top-part'>
                    <div>
                        <div className="permisos_title">
                            <div className="top-letters">
                                <img className="icon-login" src={favicom} alt='mcewanlogo' />
                                <h3>PERMISOS</h3>
                            </div>
                            <div className="aux-line-permisos" />
                        </div>
                    </div>
                    <button>X</button>
                </div>
                <div className='permisos-middle-part'>
                    <div className='module'>
                        <div className='module-prop'>
                            <p>Permiso1</p>
                        </div>
                        <img src={iconTrash} alt='mcewantrash' />
                    </div>
                    <div className='module'>
                        <div className='module-prop'>
                            <p>Permiso1</p>
                        </div>
                        <img src={iconTrash} alt='mcewantrash' />
                    </div>
                </div>
                <div className='permisos-bottom-part'>
                    <form className='add-module' onSubmit={handleSubmit}>
                            <input value={permiso} className='module-prop' placeholder='Nuevo Permiso'
                            onChange={(permiso) => setPermiso(permiso.target.value)}/>
                        <button><img src={add} alt='mcewanadd' /></button>
                    </form>
                </div>

            </div>
        </div>
    );
}

export default EditPermisos;