import React, { useState, useEffect, useContext } from "react";
import { search, fondo } from '../providers';
import Header from "../modules/Header";
import userEvent from "@testing-library/user-event";
import { UserContext } from "../context/UserContext";


function NewFacturaVenta() {
    const [user, setUser] = useContext(UserContext);
    const [asuntos, setAsuntos] = useState([]);
    const [Clientes, setClientes] = useState([]);

    const [facturaState, setFacturaState] = useState("asunto");

    const [nombre, setNombre] = useState("");
    const [nombreAsunto, setNombreAsunto] = useState("");
    const [codigo, setCodigo] = useState("");
    const [activo, setActivo] = useState(false);
    const [cobrable, setCobrable] = useState(false);
    const [IDconvenio, setIDconvenio] = useState("");
    const [NroAsunto, setNroAsunto] = useState("");
    const [dateFrom, setDateFrom] = useState();
    const [dateTo, setDateTo] = useState();

    const [dateBill, setDateBill] = useState("");
    const [dateVto, setDateVto] = useState("");
    const [moneda, setMoneda] = useState("");
    const [nombreFactura, setNombreFactura] = useState("");
    const [tipo, setTipo] = useState("1");
    const [pago, setPago] = useState("1");
    const [documento, setDocumento] = useState();
    const [importeTotal, setImporteTotal] = useState();
    const [importeImpuestos, setImporteImpuestos] = useState();
    const [importeGravado, setImporteGravado] = useState();
    const [importeMPrincipipal, setImporteMPrincipipal] = useState();
    const [estadoMail, setEstadoMail] = useState("No Enviado");
    const [cotizacion, setCotizacion] = useState(1);
    const [deposito, setDeposito] = useState("Deposito Universal");


    const [asunto, setAsunto] = useState();
    const [cliente, setCliente] = useState();
    const [monto, setMonto] = useState();

    let montoGravado = 0;
    let montoImpuesto = 0;
    let montoTotal = 0;
    let importeMonPrincipal = 0;

    const getProjects = async () => {
        await fetch("https://app-mcewan.herokuapp.com/timebilling/getProjects", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "token": user.tokenTimeBilling
            })
        })
            .then(response => response.json())
            .then((value) => {
                console.log(value);
                setAsuntos(value);
            })
            .catch(error => console.log(error));
    }
    const getPayments = async (idP) => {
        let valuereturn;
        await fetch("https://app-mcewan.herokuapp.com/timebilling/getPayments/" + idP, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "token": user.tokenTimeBilling
            })
        })
            .then(response => response.json())
            .then((value) => {
                setMonto(value);
                console.log(value);
                valuereturn = value;
            })
            .catch(error => console.log(error));
        console.log(valuereturn);
        return valuereturn;
    }



    const getClientes = async () => {
        await fetch("https://app-mcewan.herokuapp.com/xubio/getClientes",
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "access_token": user.tokenXubio
                })
            })
            .then(response => response.json())
            .then((value) => setClientes(value))
            .catch(error => console.log(error));
    }

    useEffect(() => {
        (async () => {
            await getClientes();
            await getProjects();
        })();
    }, [user]);


    const getAsunto = (value) => {
        let mG = 0;
        let mI = 0;
        let mT = 0;
        let mMP = 0;

        var newName = value.client_name.replace(" FAMILIA", "");
        var date = new Date(value.created_at);
        var dateParse = date.getFullYear() + "-" + "0" + date.getMonth() + "-" + "0" + date.getDate();
        setAsunto(value);
        setFacturaState("cliente");
        setNombre(newName);
        setDateBill(dateParse);
        setMoneda(value.currency_code);
        setNombreFactura(value.name);

        getPayments(value.id).then((value) => {
            if (value.length > 0) {
                value.map((value, index) => {
                    mG = mG + parseFloat(value.amount);
                })
            }
            setImporteGravado(mG);
            setImporteImpuestos(mI = mG / 100 * 21);
            setImporteTotal(mT = mI + mG);
            setImporteMPrincipipal(mMP = mT * cotizacion);
            console.log(mG);
            console.log(mT);
            console.log(value);
        })

        montoGravado = mG;
        montoImpuesto = mI;
        montoTotal = mT;
        importeMonPrincipal = mMP;
    }

    const getCliente = (value) => {
        setCliente(value);
        setNombre(value.nombre);

        setFacturaState("factura");
    }
    return (

        <div className="container_general">
            {console.log(monto)}
            <img src={fondo} className='fondo' alt='mcewanfondo' />
            <Header />
            <div className="body_container">
                {
                    facturaState === "factura" ? (
                        <>
                            <div className="table_card">
                                <table>
                                    <tr>
                                        <th>ID</th>
                                        <th>Nro Asunto</th>
                                        <th>Nombre</th>
                                        <th>Convenio ID</th>
                                        <th>Código de cliente</th>
                                        <th>Nombre del cliente</th>
                                        <th>Creado en</th>
                                        <th>Moneda</th>
                                        <th>Activo</th>
                                        <th>Cobrable</th>
                                    </tr>
                                    <tr>
                                        <td>{asunto.id}</td>
                                        <td>{asunto.code}</td>
                                        <td>{asunto.name}</td>
                                        <td>{asunto.agreement_id}</td>
                                        <td>{asunto.client_code}</td>
                                        <td>{asunto.client_name}</td>
                                        <td>{asunto.created_at}</td>
                                        <td>{asunto.currency_code}</td>
                                        <td>{asunto.active}</td>
                                        <td>{asunto.collectable}</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="table_card">
                                <table>
                                    <tr>
                                        <th>Nro</th>
                                        <th>Nombre</th>
                                    </tr>
                                    <tr>

                                        <td>{cliente.cliente_id}</td>
                                        <td>{cliente.nombre}</td>

                                    </tr>
                                </table>
                            </div>
                            <div className="table_card">
                                <table>

                                    <tr>
                                        <th>Nombre</th>
                                        <th>Monto</th>
                                        <th>Fecha</th>
                                    </tr>
                                    {
                                        monto.length > 0 ? (
                                            monto.map((value, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{value.name}</td>
                                                        <td>{value.amount}</td>
                                                        <td>{value.date}</td>
                                                    </tr>
                                                );
                                            })
                                        ) : null
                                    }
                                </table>
                            </div>
                            <div className="new-factura">
                                <form>
                                    <div className="form-group">
                                        <p>Nombre</p>
                                        <div className="input-case">
                                            <input type="text" className="search_input"
                                                value={nombreFactura} readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <p>Nombre Cliente</p>
                                        <div className="input-case">
                                            <input type="text" className="search_input"
                                                value={nombre} readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <p>Moneda</p>
                                        <div className="input-case">
                                            <input type="text" className="search_input"
                                                value={moneda} readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <p>Cotización</p>
                                        <div className="input-case">

                                            <input type="text" className="search_input"
                                                value={cotizacion} onChange={(value) => setCotizacion(value.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <p>Fecha</p>
                                        <div style={{ width: '50%' }} className="input-case">
                                            <input type="date" className="search_input"
                                                value={dateBill} onChange={(value) => setDateBill(value.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <p>Fecha de Vencimiento</p>
                                        <div style={{ width: '50%' }} className="input-case">
                                            <input type="date" className="search_input"
                                                value={dateVto} onChange={(value) => setDateVto(value.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <p>Tipo</p>
                                        <div className="input-case">
                                            <select className="search_input"
                                                value={tipo} onChange={(value) => setTipo(value.target.value)}
                                            >
                                                <option value={"1"}>Factura</option>
                                                <option value={"2"}>Nota de débito</option>
                                                <option value={"3"}>Nota de credito</option>
                                                <option value={"4"}>Recibo</option>
                                                <option value={"5"}>Informe de Cierre</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <p>Condicion de Pago</p>
                                        <div className="input-case">
                                            <select className="search_input"
                                                value={pago} onChange={(value) => setPago(value.target.value)}
                                            >
                                                <option value={"1"}>Cuenta Corriente</option>
                                                <option value={"2"}>Contado</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <p>Nro de Documento</p>
                                        <div className="input-case">
                                            <input type="text" className="search_input"
                                                value={documento} onChange={(value) => setDocumento(value.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <p>Importe Gravado</p>
                                        <div className="input-case">
                                            <input type="text" className="search_input"
                                                value={importeGravado} readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <p>Importe Impuestos</p>
                                        <div className="input-case">

                                            <input type="text" className="search_input"
                                                value={importeImpuestos} readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <p>Importe total</p>
                                        <div className="input-case">

                                            <input type="text" className="search_input"
                                                value={importeTotal} onChange={(value) => setImporteTotal(value.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <p>Importe Monto Principal</p>
                                        <div className="input-case">
                                            <input type="text" className="search_input"
                                                value={importeMPrincipipal}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <p>Deposito</p>
                                        <div className="input-case">
                                            <select className="search_input"
                                                value={deposito} onChange={(value) => setDeposito(value.target.value)}
                                            >
                                                <option value={"Deposito Universal"}>Deposito Universal</option>
                                            </select>
                                        </div>
                                    </div>



                                    <div className="form-group">
                                        <p>Estado de Mail</p>
                                        <div className="input-case">
                                            <select className="search_input"
                                                value={estadoMail} onChange={(value) => setEstadoMail(value.target.value)}
                                            >
                                                <option value={"No Enviado"}>No Enviado</option>
                                                <option value={"Enviado"}>Enviado</option>
                                                <option value={"Pendiente"}>Pendiente</option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </>

                    ) : null
                }
                {
                    facturaState === "cliente" ? (
                        <div>
                            <div className="general-filters">
                                <form>
                                    <div className="form-group">
                                        <p>Nombre Cliente</p>
                                        <div className="input-case">
                                            <input type="text" className="search_input"
                                                value={nombre} onChange={(value) => setNombre(value.target.value)}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div style={{ width: 'auto' }} className="table_card">
                                <table>
                                    <tr>
                                        <th>Nro</th>
                                        <th>Nombre</th>
                                    </tr>
                                    {
                                        Clientes.length > 0 ? (
                                            Clientes.filter((val) => {
                                                if (nombre === "") {
                                                    return val;
                                                } else if (val.nombre.toLowerCase().includes(nombre.toLowerCase())) {
                                                    return val;
                                                }

                                            }
                                            ).map((value, index) => {
                                                return (
                                                    <tr key={index} onClick={() => { getCliente(value) }}>
                                                        <td>{value.cliente_id}</td>
                                                        <td>{value.nombre}</td>
                                                    </tr>
                                                );
                                            })
                                        ) : null
                                    }
                                </table>
                            </div>
                        </div>
                    ) : null
                }
                {
                    facturaState === "asunto" ? (
                        <div style={{ marginTop: 0 }} className="body_container">
                            <div className="general-filters">
                                <form>
                                    <div className="form-group">
                                        <p>Nombre Cliente</p>
                                        <div className="input-case">
                                            <input type="text" className="search_input"
                                                value={nombre} onChange={(value) => setNombre(value.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <p>Nombre Asunto</p>
                                        <div className="input-case">
                                            <input type="text" className="search_input"
                                                value={nombreAsunto} onChange={(value) => setNombreAsunto(value.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <p>Codigo Cliente</p>
                                        <div className="input-case">
                                            <input type="text" className="search_input"
                                                value={codigo} onChange={(value) => setCodigo(value.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <p>Nro de Convenio</p>
                                        <div className="input-case">
                                            <input type="text" className="search_input"
                                                value={IDconvenio} onChange={(value) => setIDconvenio(value.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <p>Nro de Asunto</p>
                                        <div className="input-case">
                                            <input type="text" className="search_input"
                                                value={NroAsunto} onChange={(value) => setNroAsunto(value.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <p>Solo Activos</p>
                                        <div className="input-case">
                                            <input type="checkbox" className="search_input"
                                                value={activo} onChange={(value) => setActivo(value.target.checked)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <p>Solo Cobrables</p>
                                        <div className="input-case">
                                            <input type="checkbox" className="search_input"
                                                value={cobrable} onChange={(value) => setCobrable(value.target.checked)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <p>Fecha Desde</p>
                                        <div style={{ width: '32%' }} className="input-case">
                                            <input type="date" className="search_input"
                                                value={dateFrom} onChange={(value) => setDateFrom(value.target.value)}
                                            />
                                        </div>
                                        <p>Fecha Hasta</p>
                                        <div style={{ width: '32%' }} className="input-case">
                                            <input type="date" className="search_input"
                                                value={dateTo} onChange={(value) => setDateTo(value.target.value)}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="table_card">
                                <table>
                                    <tr>
                                        <th>ID</th>
                                        <th>Nro Asunto</th>
                                        <th>Nombre</th>
                                        <th>Convenio ID</th>
                                        <th>Código de cliente</th>
                                        <th>Nombre del cliente</th>
                                        <th>Creado en</th>
                                        <th>Moneda</th>
                                        <th>Activo</th>
                                        <th>Cobrable</th>
                                    </tr>
                                    {
                                        asuntos.length > 1 ? (
                                            asuntos.filter((val) => {
                                                if (nombre === "") {
                                                    return val;
                                                } else if (val.client_name.toLowerCase().includes(nombre.toLowerCase())) {
                                                    return val;
                                                }
                                            }
                                            ).filter((val) => {
                                                if (nombreAsunto === "") {
                                                    return val;
                                                } else if (val.name.toLowerCase().includes(nombreAsunto.toLowerCase())) {
                                                    return val;
                                                }
                                            }).filter((val) => {
                                                if (codigo === "") {
                                                    return val;
                                                } else if (val.client_code.toLowerCase().includes(codigo.toLowerCase())) {
                                                    return val;
                                                }
                                            }).filter((val) => {
                                                if (NroAsunto === "") {
                                                    return val;
                                                } else if (val.code.toLowerCase().includes(NroAsunto.toLowerCase())) {
                                                    return val;
                                                }
                                            }).filter((val) => {
                                                if (IDconvenio === "") {
                                                    return val;
                                                } else if (IDconvenio === val.agreement_id) {
                                                    return val;
                                                }
                                            }).filter((val) => {
                                                if (activo === false) {
                                                    return val;
                                                } else if (val.active === "1" && activo === true) {
                                                    return val;
                                                }
                                            }).filter((val) => {
                                                if (cobrable === false) {
                                                    return val;
                                                } else if (val.collectable === "1" && cobrable === true) {
                                                    return val;
                                                }
                                            }).filter((val) => {
                                                let dateCheck;
                                                let tsDate;
                                                let tsDateTo;
                                                tsDateTo = Date.parse(dateTo);
                                                tsDate = Date.parse(dateFrom);
                                                dateCheck = Date.parse(val.created_at)
                                                if (dateFrom === undefined || dateTo === undefined) {
                                                    return val;
                                                } else if (dateCheck >= tsDate && dateCheck <= tsDateTo) {
                                                    return val;
                                                }
                                            }).map((value, index) => {
                                                var activoState, cobrableState;
                                                value.active === '1' ? activoState = 'Si' : activoState = 'No'
                                                value.collectable === '1' ? cobrableState = 'Si' : cobrableState = 'No'

                                                return (
                                                    <tr key={index} onClick={() => { getAsunto(value) }}>
                                                        <td>{value.id}</td>
                                                        <td>{value.code}</td>
                                                        <td>{value.name}</td>
                                                        <td>{value.agreement_id}</td>
                                                        <td>{value.client_code}</td>
                                                        <td>{value.client_name}</td>
                                                        <td>{value.created_at}</td>
                                                        <td>{value.currency_code}</td>
                                                        <td>{activoState}</td>
                                                        <td>{cobrableState}</td>
                                                    </tr>
                                                );
                                            })
                                        ) : null

                                    }
                                </table>
                            </div>
                        </div>
                    ) : null
                }
            </div>
        </div>
    );
}

export default NewFacturaVenta;