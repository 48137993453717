import React, { useState } from 'react';
import { favicom, fondo } from '../providers';

function CreateUser() {
    const [error, setError] = useState("");
    const [nombre, setNombre] = useState("");
    const [edad, setEdad] = useState();
    const [genero, setGenero] = useState("");
    const [userTB, setUserTB] = useState("");
    const [newUser, setNewUser] = useState("");
    const [userXubio, setUserXubio] = useState("");
    const [rol, setRol] = useState("");
    const [permiso, setPermiso] = useState("");
    const [isLoading, setLoading] = useState(false);

    const handleCreateUser = (e) => {
        e.preventDefault();
        try {
            setError("");
            setLoading(!isLoading);
            setTimeout(() => {
                if (nombre!=='' && edad>18 && edad!=='' && genero!=='' 
                && userTB !=='' && userXubio !== '' && newUser!=='' && rol!=='' && permiso!=='')
                    alert("yes");
                else
                    setError("Complete todos los campos de forma correcta para continuar.");
                setLoading(false);
            }, 1500);
        }
        catch (error) {
            alert(error);
        }
    }

    return (
        <div className="container_general">
            <img src={fondo} className='fondo' alt='mcewanfondo' />
            <div className="create-user_card">
                <div className="create-user_title">
                    <div className="top-letters">
                        <img className="icon-login" src={favicom} alt='mcewanlogo' />
                        <h3>CREAR USUARIO</h3>
                    </div>
                    <div className="aux-line" />
                </div>
                <form className="create-user_form_container" onSubmit={handleCreateUser}>
                    <h2>Datos personales</h2>
                    <div className="error_container">
                        <span>{error}</span>
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <div className="input_container">
                            <p>Nombre y Apellido</p>
                            <input type="text"
                                value={nombre}
                                onChange={(nombre) => setNombre(nombre.target.value)} />
                        </div>
                        <div className='first-group'>
                            <div style={{ width: '40%' }} className="input_container">
                                <p>Edad</p>
                                <input style={{ maxWidth: 130 }} type="number" min="0" max="100"
                                    value={edad}
                                    onChange={(edad) => setEdad(edad.target.value)} />
                            </div>
                            <div style={{ width: '50%' }} className="input_container">
                                <p>Genero</p>
                                <select style={{ maxWidth: 130 }}
                                    value={genero}
                                    onChange={(genero) => setGenero(genero.target.value)}>
                                    <option value='Masculino'>Masculino</option>
                                    <option value='Femenino'>Femenino</option>
                                    <option value='Otro'>Otro</option>
                                </select>
                                <i></i>
                            </div>
                        </div>
                        <div className='second-group'>
                            <div className="input_container">
                                <p>Usuario de TimeBilling</p>
                                <input type="text"
                                    value={userTB}
                                    onChange={(userTB) => setUserTB(userTB.target.value)} />
                            </div>
                            <div className="input_container">
                                <p>Usuario de Xubio</p>
                                <input type="text"
                                    value={userXubio}
                                    onChange={(userXubio) => setUserXubio(userXubio.target.value)} />
                            </div>
                        </div>
                        <div className=" third-group input_container">
                            <p>Usuario de McEwanSystem</p>
                            <input type="text"
                                value={newUser}
                                onChange={(newUser) => setNewUser(newUser.target.value)} />
                        </div>
                        <div className='fourth-group'>
                            <div className="input_container">
                                <p>Roles</p>
                                <select 
                                value={rol}
                                onChange={(rol) => setRol(rol.target.value)}>
                                    <option value='admin'>administrador</option>
                                    <option value='user'>usuario</option>
                                    <option value='editor'>editor</option>
                                </select>
                                <i></i>
                            </div>
                            <div className="input_container">
                                <p>Permisos</p>
                                <select 
                                value={permiso}
                                onChange={(permiso) => setPermiso(permiso.target.value)}>
                                    <option value='admin'>administrador</option>
                                    <option value='user'>usuario</option>
                                    <option value='editor'>editor</option>
                                </select>
                                <i></i>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: "right", marginTop: 30 }}>
                        <div className="button_container">
                            <button className="button-line-login" onClick={handleCreateUser}>
                                {isLoading ? "Cargando" : "Aceptar"}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CreateUser;