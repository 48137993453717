import React, { useState, useEffect, useContext } from "react";
import testingAccess from '../providers/testinAccess.json';
import { logo, fondo } from '../providers';
import LineGraph from "../modules/LineGraph";
import BarGraph from "../modules/BarGraph";
import PieGraph from "../modules/PieGraph";
import Header from "../modules/Header";
import ModalDash from '../modules/ModalDash';
import { UserContext } from "../context/UserContext";


function General() {
    const [token, setToken] = useState();
    const [compra, setCompra] = useState([]);
    const [datosCompra, setDatosCompra] = useState([]);
    const [user, setUser] = useContext(UserContext);
    const [visible, setVisible] = useState(false);

    const openModal = () => {
        setVisible(!visible);
    }

    const getCompra = async () => {
        await fetch("https://app-mcewan.herokuapp.com/xubio/getFacturaCompra",
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "access_token": user.tokenXubio
                })
            })
            .then(response => response.json())
            .then((value) => {
                setCompra(value);
            })
            .catch(error => console.log(error));

    }

    useEffect(() => {
        (async () => {
            /*await fetch("https://app-mcewan.herokuapp.com/xubio/getToken",
                {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "client_id": testingAccess.client_id,
                        "secret_id": testingAccess.secret_id
                    })
                })
                .then(response => response.json())
                .then((value) => setToken(value.access_token))
                .catch(error => console.log(error));*/
            await getCompra();

        })();
    }, [token]);

    return (
        <div className="container_general">
            {/*
                compra.map(value =>{
                    var date = new Date(value.fecha); 
                    console.log(date.getMonth()+1);
                })
                */

            }
            <ModalDash visible={visible}/>
            <div className="dashboard-body">
                <img src={fondo} className='fondo' alt='mcewanfondo' />
                <Header />
                <div className="left-side-dashboard">
                    <div className="dashboard_search">
                        <button onClick={openModal}>
                            +
                        </button>
                    </div>
                </div>
                <div className="right-side-dashboard">
                    <LineGraph />
                    <BarGraph />
                    <PieGraph />
                    
                </div>
            </div>

        </div>
    );
}

export default General;