/// Import css styles for all components
import '../screens/css/index.css';
import '../modules/css/index.css';
/// Import css styles for all components
import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes
} from 'react-router-dom';
/// SCREENS
import Login from '../screens/Login';
import General from '../screens/General';
import Loading from '../screens/Loading';
import RecoverPassword from '../screens/RecoverPassword';
import ChangePassword from '../screens/ChangePassword';
import TestScreen from '../screens/TestScreen';
import BillsScreen from '../screens/BillsScreen';
import CreateUser from '../screens/CreateUser';
import EditPermisos from '../screens/EditPermisos';
import EditRoles from '../screens/EditRoles';
import Asuntos from '../screens/FacturasTB';
import Comprax from '../screens/CompraX';
import VentaX from '../screens/VentaX';
import ViewUsers from '../screens/ViewUsers';
import ClientesX from '../screens/ClientesX';
import ClientesTB from '../screens/ClientesTB';
import { UserProvider } from '../context/UserContext';
import AsientoContable from '../screens/AsientoContableX';
import NewFacturaVenta from '../screens/NewFacturaVenta';
/// SCREENS

function App() {
    return (
        <UserProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<Loading />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/dashboard/general" element={<General />} />
                    <Route path="/dashboard/test" element={<TestScreen />} />
                    <Route path="/dashboard/Bills" element={<BillsScreen />} />
                    <Route path="/recoverpassword" element={<RecoverPassword />} />
                    <Route path="/changepassword" element={<ChangePassword />} />
                    <Route path="/createUser" element={<CreateUser />} />
                    <Route path="/editPermisos" element={<EditPermisos />} />
                    <Route path="/editRoles" element={<EditRoles />} />
                    <Route path="/dashboard/timebilling/asuntos" element={<Asuntos />} />
                    <Route path="/dashboard/xubio/compra" element={<Comprax />} />
                    <Route path="/dashboard/xubio/Venta" element={<VentaX />} />
                    <Route path="/dashboard/timebilling/users" element={<ViewUsers />} />
                    <Route path="/dashboard/xubio/clientes" element={<ClientesX />} />
                    <Route path="/dashboard/timebilling/clientes" element={<ClientesTB />} />
                    <Route path="/dashboard/xubio/asientoContable" element={<AsientoContable />} />
                    <Route path="/dashboard/xubio/nuevaFacturaVenta" element={<NewFacturaVenta/>} />
                </Routes>
            </Router>
        </UserProvider>
    )
}

export default App;