import React, { useState } from 'react';
import { favicom,fondo } from '../providers';

function RecoverPassword() {
    const [user, setUser] = useState("");
    const [error, setError] = useState("");
    const [mailSetted, setMailSetted] = useState(false);
    const [popUp, setPopUp] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            setLoading(!isLoading);
            setError("");
            setTimeout(() => {
                if (user.includes("@") && user.includes(".com")) {
                    setPopUp(true);
                    setMailSetted(true);
                } else
                    setError("ingrese un mail valido.");
                setLoading(false);
            }, 1500);
        }
        catch (error) {
            alert(error);
        }
    }
    const handlePopUp = (e) => {
        e.preventDefault();
        try {
           setPopUp(false);
        }
        catch (error) {
            alert(error);
        }
    }

    return (
        <div className="container_general">
            <img src={fondo} className='fondo'alt='mcewanfondo'/>
            {
                mailSetted ?/*aca va la pantalla de cambio de contraseña xd*/ null : null
            }
            {
                popUp ?
                    (
                        <div style={{justifyContent:'start'}} className="recover_card">
                            <div className='popUp'>
                                <button onClick={handlePopUp}>X</button>
                                <div className='popUp-text'>
                                   <h3>Se ha enviado un mail a tu cuenta de usuario</h3> 
                                </div>
                            </div>
                            
                        </div>
                    ) : (
                        <div className="recover_card">
                            <div className="recover_card_before"></div>
                            <div className="recover_title">
                                <div className="top-letters">
                                    <img className="icon-login" src={favicom} alt='mcewanicon'/>
                                    <h3>RECUPERAR CONTRASEÑA</h3>
                                </div>
                                <div className="aux-line-recover" />
                            </div>
                            <form className="recover_form_container" onSubmit={handleSubmit}>
                                <div className="error_container">
                                    <span>{error}</span>
                                </div>
                                <div style={{ display: 'flex', textAlign: "right" }}>
                                    <div className="input_container">
                                        <input type="email" placeholder="Ingrese su mail" value={user}
                                            onChange={(user) => setUser(user.target.value)}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: "right" }}>
                                    <div className="button_container">
                                        <button className="button-line-login"
                                            onClick={handleSubmit}
                                        >
                                            {isLoading ? "Cargando" : "Aceptar"}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )
            }
        </div>
    );
}

export default RecoverPassword;