import React,{useState} from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { ResponsiveContainer } from "recharts";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function LineGraph() {
  const data = {
   labels:[
    'Ene',
   'Feb',
   'Mar',
   'Abr',
   'May',
   'Jun',
   'Jul',
   'Ago',
   'Sep',
   'Oct',
   'Nov',
   'Dic'],
   datasets:[{
    label: 'negro',
    data: [65, 59, 70, 81, 56, 55, 40,59, 70, 81,66,69],
    fill: true,
    backgroundColor:'#d42e12',
    borderColor: '#d42e12',
    pointBorderColor:'#6d6d6d',
    tension: 0.1
   },{
    label: 'blanco',
    data: [66, 69, 80, 85, 59, 45, 53,80, 85, 59,69, 79],
    fill: true,
    borderColor: '#6d6d8d',
    backgroundColor:'#6d6d8d',
    pointBorderColor:'#6d6d6d',

    tension: 0.1
   },{
    label: 'no trabajados',
    data: [69, 79, 86, 84, 96, 110, 91,79, 86, 84,45,75],
    fill: true,
    backgroundColor:'#004557',
    borderColor: '#004557',
    pointBorderColor:'#6d6d6d',

    tension: 0.1
   },]
  }; 
    return (
        <div className="graph-overlay">
            <div className="top-part">
                <p>INFORME X1</p>
            </div>
            <div className="bottom-part">
            <Line data={data}
            options={{
              responsive: true,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      autoSkip: true,
                      maxTicksLimit: 15,
                      beginAtZero: true
                    }
                  }
                ],
                xAxes: [
                  {
                    gridLines: {
                      display: false
                    }
                  }
                ]
              }
            }}/>
            </div>

        </div>
    );
}

export default LineGraph;