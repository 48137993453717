import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { Link } from "react-router-dom";
import { UserContext } from '../context/UserContext';
import { favicom, testingAccess, fondo } from '../providers';

function Login() {
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [isLogged, setLogged] = useState(false);
    const [error, setError] = useState("");
    const [userProvider, setUserProvider] = useContext(UserContext);

    const getXubioToken = async (client_id, secret_id) => {
        let log_accs = {};
        await fetch("https://app-mcewan.herokuapp.com/xubio/getToken",
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "client_id": client_id,
                    "secret_id": secret_id
                })
            })
            .then(response => response.json())
            .then((value) => {
                log_accs = value;
            })
            .catch(error => console.log(error));

        return log_accs;
    }

    const getTimebillingToken = async (user, password, app_key) => {
        let log_accs = {};
        await fetch("https://app-mcewan.herokuapp.com/timebilling/getToken", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "user": user,
                "password": password,
                "app_key": app_key
            })
        })
            .then(response => response.json())
            .then((value) => {
                log_accs = value;
            })
            .catch(error => console.log(error));

        return log_accs;
    }

    const getUserLogged = async (id, tokenTB) => {
        let logged_user = {};
        await fetch("https://app-mcewan.herokuapp.com/timebilling/getUser/" + id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "token": tokenTB
            })
        })
            .then(response => response.json())
            .then((value) => {
                logged_user = value;
            })
            .catch(error => console.log(error));

        return logged_user;
    }

    const handleLogin = async (e) => {
        let tokenTime;
        let tokenXu;
        let id;
        let responseUser = null;
        e.preventDefault();
        try {
            setError("");
            setLoading(!isLoading);
            if (user === testingAccess.user && password === testingAccess.password) {
                setLogged(!isLogged);
                await getXubioToken(testingAccess.client_id, testingAccess.secret_id)
                    .then(value => {
                        tokenXu = value.access_token;
                    });
                await getTimebillingToken(testingAccess.user_timebilling, testingAccess.password_timebilling, testingAccess.app_key)
                    .then(value => {
                        tokenTime = value.auth_token;
                        id = value.user_id;
                    });
                if (tokenTime !== "" && tokenXu !== "") {
                    await getUserLogged(id, tokenTime).then(value => responseUser = value);
                    if (responseUser) {
                        console.log(responseUser);
                        setUserProvider({
                            id: responseUser.id,
                            code: responseUser.code,
                            cost_centre: responseUser.cost_centre,
                            email: responseUser.email,
                            firstname: responseUser.firstname,
                            lastname1: responseUser.lastname1,
                            lastname2: responseUser.lastname2,
                            name: responseUser.name,
                            permissions: responseUser.permissions,
                            phone: responseUser.phone,
                            settings: responseUser.settings,
                            user_area_id: responseUser.user_area_id,
                            user_category_id: responseUser.user_category_id,
                            username: responseUser.username,
                            visible: responseUser.visible,
                            xubio_login: {
                                "client_id": testingAccess.client_id,
                                "secret_id": testingAccess.secret_id
                            },
                            timebilling_login: {
                                "user": testingAccess.user_timebilling,
                                "password": testingAccess.password_timebilling,
                                "app_key": testingAccess.app_key
                            },
                            tokenTimeBilling: tokenTime,
                            tokenXubio: tokenXu,
                            loggedIn: true,
                        });
                    }
                }
                else {
                    setError("Connexión rechazada");
                }
            }
            else
                setError("Usuario y/o contraseña incorrecto");
            setLoading(false);
        }
        catch (error) {
            alert(error);
        }
    }

    return (
        <div className="container_general">
            <img src={fondo} className='fondo' alt='fondologin' />
            {
                userProvider.loggedIn ? <Navigate to="/dashboard/general" /> : null
            }
            <div className="login_card">
                <div className="login_title">
                    <div className="top-letters">
                        <img className="icon-login" src={favicom} alt='logologin' />
                        <h3>LOGIN</h3>
                    </div>
                    <div className="aux-line-login" />
                </div>
                <form className="login_form_container" onSubmit={handleLogin}>
                    <div className="error_container">
                        <span>{error}</span>
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex', textAlign: "right" }}>
                        <div className="input_container">
                            <input type="email" placeholder="Usuario" value={user}
                                onChange={(user) => setUser(user.target.value)}
                            />
                        </div>
                        <div className="input_container">
                            <input type="password" security placeholder="Contraseña" value={password}
                                onChange={(password) => setPassword(password.target.value)}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: "right" }}>
                        <div className="button_container">
                            <button className="button-line-login"
                                onClick={handleLogin}>
                                {isLoading ? "Cargando" : "Iniciar sesión"}
                            </button>
                        </div>
                        <div className="button_container">
                            <button className="button-line-login">
                                <Link to="/recoverpassword">
                                    Recuperar contrañesa
                                </Link>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;