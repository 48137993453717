import React, { createContext, useState } from 'react';

const UserContext = createContext([{}, () => { }]);

const UserProvider = (props) => {
    const [state, setState] = useState({
        id: 0,
        active: 0,
        code: 0,
        cost_centre: "",
        firstname: "",
        lastname1: "",
        lastname2: "",
        name: "",
        permissions: {},
        phone: null,
        settings: {},
        user_area_id: "",
        user_category_id: "",
        username: "",
        visible: "",
        xubio_login: {},
        timebilling_login: {},
        tokenTimeBilling: "",
        tokenXubio: "",
        loggedIn: null,
    });

    return (
        <UserContext.Provider value={[state, setState]}>
            {props.children}
        </UserContext.Provider>
    );
}

export { UserContext, UserProvider };