import React, { useState } from 'react';
import testingAccess from '../providers/testinAccess.json';
import { favicom, fondo } from '../providers';

function BillsScreen() {
    const [tableState, setTableState] = useState("");
    const [token, setToken] = useState("");
    const [venta, setVenta] = useState([]);
    const [compra, setCompra] = useState([]);
    const [clients, setClients] = useState([]);
    const getClients = async () => {
        await fetch("https://app-mcewan.herokuapp.com/getClientes",
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "access_token": token
                })
            })
            .then(response => response.json())
            .then((value) => setClients(value))
            .catch(error => console.log(error))
    }

    const getToken = async () => {
        await fetch("https://app-mcewan.herokuapp.com/getToken",
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "client_id": testingAccess.client_id,
                    "secret_id": testingAccess.secret_id
                })
            })
            .then(response => response.json())
            .then((value) => setToken(value.access_token))
            .catch(error => console.log(error));
    }

    const getFacturaCompra = async () => {
        await fetch("https://app-mcewan.herokuapp.com/getFacturaCompra",
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "access_token": token
                })
            })
            .then(response => response.json())
            .then((value) => setCompra(value))
            .catch(error => console.log(error));
    }

    const getFacturaVenta = async () => {
        await fetch("https://app-mcewan.herokuapp.com/getFacturaVenta",
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "access_token": token
                })
            })
            .then(response => response.json())
            .then((value) => setVenta(value))
            .catch(error => console.log(error));
    }


    return (
        <div className="container_general">
            <img src={fondo} className='fondo' alt='fondologin' />
            <div className='bills-test'>
                <div style={{ flexDirection: 'row', display: 'flex' }}>
                    <button onClick={getToken}>get Token</button>
                    <button onClick={() => {
                        getFacturaCompra();
                        setTableState("compra");
                    }}>get Factura compra</button>
                    <button onClick={() => {
                        getFacturaVenta();
                        setTableState("venta");
                    }}
                    >get Factura venta</button>
                    <button onClick={() => {
                        getClients();
                        setTableState("clientes");
                    }}>get Clientes</button>
                </div>

                <p>{token}</p>
                {
                    tableState === "compra" ? (
                        <table>
                            <tr>
                                <th>Cotización</th>
                                <th>Fecha</th>
                                <th>Fecha Comprobante</th>
                                <th>Importe Gravado</th>
                                <th>Importe Impuestos</th>
                                <th>Importe Principal</th>
                                <th>moneda</th>
                                <th>Numero Documento</th>
                                <th>Proveedor</th>
                                <th>Tipo</th>
                                <th>Transaccion ID</th>
                            </tr>
                            {
                                compra.map(value => {
                                    return (
                                        <tr>
                                            <td>{value.cotizacion}</td>
                                            <td>{value.fecha}</td>
                                            <td>{value.fechaComprobante}</td>
                                            <td>{value.importeGravado}</td>
                                            <td>{value.importeImpuestos}</td>
                                            <td>{value.importeMonPrincipal}</td>
                                            <td>{value.moneda.nombre}</td>
                                            <td>{value.numeroDocumento}</td>
                                            <td>{value.proveedor.nombre}</td>
                                            <td>{value.tipo}</td>
                                            <td>{value.transaccionid}</td>
                                        </tr>
                                    )
                                })
                            }
                        </table>) : null
                }
                {
                    tableState === "venta" ? (
                        <table>
                            <tr>
                                <th>CAE</th>
                                <th>CAE Vencimiento</th>
                                <th>Cliente</th>
                                <th>Cotización</th>
                                <th>Fecha</th>
                                <th>Fecha de Vto</th>
                                <th>Importe Gravado</th>
                                <th>Importe Impuestos</th>
                                <th>Importe Principal</th>
                                <th>Importe total</th>
                                <th>Estado de Mail</th>
                                <th>Moneda</th>
                                <th>Numero Documento</th>
                                <th>Punto de venta</th>
                                <th>Tipo</th>
                                <th>Transaccion ID</th>
                            </tr>
                            {
                                venta.map(value => {
                                    value.importeMonPrincipal=parseFloat(value.importeMonPrincipal);
                                    return (
                                        <tr>
                                            <td>{value.CAE}</td>
                                            <td>{value.CAEFechaVto}</td>
                                            <td>{value.cliente.nombre}</td>
                                            <td>{value.cotizacion}</td>
                                            <td>{value.fecha}</td>
                                            <td>{value.fechaVto}</td>
                                            <td>{value.importeGravado}</td>
                                            <td>{value.importeImpuestos}</td>
                                            <td>{value.importeMonPrincipal}</td>
                                            <td>{value.importetotal}</td>
                                            <td>{value.mailEstado}</td>
                                            <td>{value.moneda.nombre}</td>
                                            <td>{value.numeroDocumento}</td>
                                            <td>{value.puntoVenta.nombre}</td>
                                            <td>{value.tipo}</td>
                                            <td>{value.transaccionid}</td>
                                        </tr>
                                    )
                                })
                            }
                        </table>) : null
                }
                {
                    tableState === "clientes" ? (
                        <table>
                            <tr>
                                <th>Client ID</th>
                                <th>Name</th>
                            </tr>
                            {
                                clients.map(value => {
                                    return (
                                        <tr>
                                            <td>{value.cliente_id}</td>
                                            <td>{value.nombre}</td>
                                        </tr>
                                    )
                                })
                            }
                        </table>) : null
                }

            </div>

        </div>

    );
}

export default BillsScreen;