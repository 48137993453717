import React, { useContext, useState } from 'react';
import { UserContext } from '../context/UserContext';
import { search, logo } from '../providers';
import { Link as A } from 'react-router-dom';

const Header = () => {
    const [user, _] = useContext(UserContext);

    const changeStatus = () => {
        const sidebar = document.querySelector(".side-bar");
        const hamburger = document.querySelector(".hamburger");
        sidebar.classList.toggle("no-desplegado");
        hamburger.classList.toggle("open");
    }


    const [TBmenu, setTBmenu] = useState(false);
    const [Xmenu, setXmenu] = useState(false);

    return (
        <div className="nav-master">
            <div className="header">
                <div className="left">
                    <button onClick={changeStatus}
                        className="hamburger">
                        <div className='line'></div>
                        <div className='line'></div>
                        <div className='line'></div>
                    </button>
                </div>
                <div className="logo">
                    <img alt='mcewan logo' src={logo} className="logo_img" />
                </div>
            </div>
            <nav className="side-bar no-desplegado">
                <div className="info-user">
                    <div className="image-aux" />
                    <h5>{user.username}</h5>
                    <small>{user.email}</small>
                </div>
                <ul id="main-menu" class="side-menu">
                    <li class="header-li">Main</li>
                    <li class=""><A to="/dashboard/general"><span>Dashboard</span></A></li>
                    <li onClick={() => setTBmenu(!TBmenu)}><a><span>TimeBilling</span></a>
                        <ul className={`submenu ${TBmenu ? "active" : ""}`}>
                            <li><A to="/dashboard/timebilling/clientes"><span>Clientes</span></A></li>
                            <li><A to="/dashboard/timebilling/asuntos"><span>Asuntos</span></A></li>
                            <li><A to="/dashboard/timebilling/users"><span>Usuarios</span></A></li>
                        </ul>
                    </li>
                    <li onClick={() => setXmenu(!Xmenu)}><a><span>Xubio</span></a>
                    <ul className={`submenu ${Xmenu ? "active" : ""}`}>
                            <li><A to="/dashboard/xubio/clientes"><span>Clientes</span></A></li>
                            <li><A to="/dashboard/xubio/compra"><span>Orden Compra</span></A></li>
                            <li><A to="/dashboard/xubio/venta"><span>Orden Venta</span></A></li>
                            <li><A to="/dashboard/xubio/asientoContable"><span>Ver asientos contables Manual</span></A></li>
                            <li><A to="/dashboard/xubio/nuevaFacturaVenta"><span>Nueva factura de Venta</span></A></li>

                        </ul>
                    </li>
                    <div className='separator' />


                    {/*<li class="header-li">Usuarios</li>
                    <li><A to="/dashboard/timebilling/users"><span>Ver usuarios</span></A></li>
    <div className='separator' />*/}

                    <li class="header-li">Cuenta</li>
                    <li><a href="#"><span>Permisos</span></a></li>
                    <li><a href="#"><span>Settings</span></a></li>
                </ul>
            </nav>
        </div>


    )
}
export default Header;