import React, { useState, useEffect, useContext } from "react";
import { fondo } from '../providers';
import Header from "../modules/Header";
import testingAccess from '../providers/testinAccess.json';
import { UserContext } from "../context/UserContext";



function VentaX() {
    const [user, _] = useContext(UserContext);
    const [Ventas, setVentas] = useState([]);

    const [nombre, setNombre] = useState("");
    const [codigo, setCodigo] = useState("");
    const [tipo, setTipo] = useState("0");
    const [dateFrom, setDateFrom] = useState();
    const [dateTo, setDateTo] = useState();

    const getFacturaCompra = async () => {
        await fetch("https://app-mcewan.herokuapp.com/xubio/getFacturaVenta",
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "access_token": user.tokenXubio
                })
            })
            .then(response => response.json())
            .then((value) => setVentas(value))
            .catch(error => console.log(error));
    }

    useEffect(() => {
        (async () => {
            await getFacturaCompra();
        })();
    }, [user.tokenXubio]);


    return (
        <div className="container_general">
            <img src={fondo} className='fondo' alt='mcewanfondo' />
            <Header />
            <div className="body_container">
            <div className="general-filters">
                    <form>
                        <div className="form-group">
                            <p>Nombre Cliente</p>
                            <div className="input-case">
                                <input type="text" className="search_input"
                                    value={nombre} onChange={(value) => setNombre(value.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <p>Codigo</p>
                            <div className="input-case">
                                <input type="text" className="search_input"
                                    value={codigo} onChange={(value) => setCodigo(value.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <p>Tipo</p>
                            <div className="input-case">
                                <select className="search_input"
                                    value={tipo} onChange={(value) => setTipo(value.target.value)}
                                >
                                    <option value={"0"}>Todos</option>
                                    <option value={"1"}>Factura</option>
                                    <option value={"2"}>Nota de débito</option>
                                    <option value={"3"}>Nota de credito</option>
                                    <option value={"4"}>Recibo</option>
                                    <option value={"5"}>Informe de Cierre</option>
                                    <option value={"10"}>Otro</option>

                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <p>Fecha Desde</p>
                            <div style={{ width: '32%' }} className="input-case">
                                <input type="date" className="search_input"
                                    value={dateFrom} onChange={(value) => setDateFrom(value.target.value)}
                                />

                            </div>
                            <p>Fecha Hasta</p>
                            <div style={{ width: '32%' }} className="input-case">
                                <input type="date" className="search_input"
                                    value={dateTo} onChange={(value) => setDateTo(value.target.value)}
                                />

                            </div>
                        </div>
                    </form>
                </div>
                <div className="table_card">
                    <table>
                        <tr>
                            <th>Codigo</th>
                            <th>CAE</th>
                            <th>CAE Vencimiento</th>
                            <th>Cliente</th>
                            <th>Cotización</th>
                            <th>Fecha</th>
                            <th>Fecha de Vto</th>
                            <th>Imp. Gravado</th>
                            <th>Imp. Impuestos</th>
                            <th>Imp. total</th>
                            <th>Imp. Principal</th>
                            {/*<th>Estado de Mail</th>*/}
                            <th>Moneda</th>
                            <th>Numero Documento</th>
                            {/*<th>Punto de venta</th>*/}
                            <th>Tipo</th>
                        </tr>
                        {console.log(Ventas),
                            Ventas.length > 1 ? (
                                Ventas.filter((val) => {
                                    if (nombre === "") {
                                        return val;
                                    } else if (val.cliente.nombre.toLowerCase().includes(nombre.toLowerCase())) {
                                        return val;
                                    }
                                }
                                ).filter((val) => {
                                    if (codigo === "") {
                                        return val;
                                    } else if (val.transaccionid.toString().includes(codigo)) {
                                        return val;
                                    }
                                }).filter((val) => {
                                    if (tipo === "0") {
                                        return val;
                                    } else if (tipo===val.tipo.toString()) {
                                        return val;
                                    }
                                }).filter((val) => {
                                    let dateCheck;
                                        let tsDate;
                                        let tsDateTo;
                                        tsDateTo = Date.parse(dateTo);
                                        tsDate = Date.parse(dateFrom);
                                        dateCheck = Date.parse(val.fecha)
                                        if (dateFrom === undefined || dateTo === undefined) {
                                            return val;
                                        } else if (dateCheck >= tsDate && dateCheck <= tsDateTo) {
                                            return val;
                                        }
                                }).map((value, index) => {
                                    value.importeMonPrincipal = parseFloat(value.importeMonPrincipal);
                                    if (value.moneda.nombre === 'Dólares') {
                                        value.moneda.nombre = 'USD'
                                    }
                                    if (value.moneda.nombre === 'Pesos Argentinos') {
                                        value.moneda.nombre = 'ARS'
                                    }
                                    var tipoDisplay;

                                    switch (value.tipo) {
                                        case 1:
                                            tipoDisplay = "Factura";
                                            break;
                                        case 2:
                                            tipoDisplay = "Nota de débito";
                                            break;
                                        case 3:
                                            tipoDisplay = "Nota de credito";
                                            break;
                                        case 4:
                                            tipoDisplay = "Recibo";
                                            break;
                                        case 5:
                                            tipoDisplay = "informe De Cierre";
                                            break;
                                        case 10:
                                            tipoDisplay = "Otro";
                                            break;

                                    }
                                    return (
                                        <tr key={index}>
                                            <td>{value.transaccionid}</td>
                                            <td>{value.CAE}</td>
                                            <td>{value.CAEFechaVto}</td>
                                            <td>{value.cliente.nombre}</td>
                                            <td>{value.cotizacion}</td>
                                            <td>{value.fecha}</td>
                                            <td>{value.fechaVto}</td>
                                            <td>{value.importeGravado}</td>
                                            <td>{value.importeImpuestos}</td>
                                            <td>{value.importetotal}</td>
                                            <td>{value.importeMonPrincipal}</td>
                                            {/*<td>{value.mailEstado}</td>*/}
                                            <td>{value.moneda.nombre}</td>
                                            <td>{value.numeroDocumento}</td>
                                            {/*<td>{value.puntoVenta.nombre}</td>*/}
                                            <td>{tipoDisplay}</td>
                                        </tr>
                                    );
                                })
                            ) : null

                        }
                    </table>
                </div>
            </div>
        </div>
    );
}

export default VentaX;