import React, { useState } from 'react';
import { favicom, fondo } from '../providers';

function ChangePassword() {
    const [pass, setPass] = useState("");
    const [passTwo, setPassTwo] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setLoading] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            setLoading(!isLoading);
            setError("");
            setTimeout(() => {
                if (pass !== "" && pass === passTwo) {
                    alert("yes");
                } else{
                    if(pass === ""){
                        setError("Introduzca una contraseña.");
                    }else{
                        setError("Las contraseñas no son iguales."); 
                    }
                    
                    setLoading(false);
                }
            }, 1500);
        }
        catch (error) {
            alert(error);
        }
    }

    return (
        <div className="container_general">
            <img src={fondo} className='fondo' alt='mcewanfondo'/>

            <div className="recover_card">
                <div className="recover_title">
                    <div className="top-letters">
                        <img className="icon-login" src={favicom} alt='mcewanlogo'/>
                        <h3>RECUPERAR CONTRASEÑA</h3>
                    </div>
                    <div className="aux-line-recover" />
                </div>
                <form className="recover_form_container" onSubmit={handleSubmit}>
                    <div className="error_container">
                        <span>{error}</span>
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex', textAlign: "right" }}>
                        <div className="input_container">
                            <input type="password" security placeholder="Nueva contraseña" value={pass}
                                onChange={(pass) => setPass(pass.target.value)}
                            />
                        </div>
                        <div className="input_container">
                            <input type="password" security placeholder="Repita contraseña" value={passTwo}
                                onChange={(passTwo) => setPassTwo(passTwo.target.value)}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: "right" }}>
                        <div className="button_container">
                            <button className="button-line-login"
                                onClick={handleSubmit}
                            >
                                {isLoading ? "Cargando" : "Cambiar"}
                            </button>
                        </div>
                    </div>
                </form>
            </div>


        </div>
    );
}

export default ChangePassword;