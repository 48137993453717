import React, { useState, useEffect, useContext } from "react";
import { search, fondo } from '../providers';
import Header from "../modules/Header";
import { UserContext } from "../context/UserContext";


function ViewUsers() {
    const [user, setUser] = useContext(UserContext);
    const [Usuarios, setUsuarios] = useState([]);
    const [id, setId] = useState();

    const [nombre, setNombre] = useState("");
    const [activo, setActivo] = useState(false);

    const getUsuarios = async () => {
        await fetch("https://app-mcewan.herokuapp.com/timebilling/getUsers", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "token": user.tokenTimeBilling
            })
        })
            .then(response => response.json())
            .then((value) => {
                console.log(value);
                setUsuarios(value);
            })
            .catch(error => console.log(error));
    }

    useEffect(() => {
        (async () => {
            await getUsuarios();
        })();
    }, [user.tokenTimeBilling]);

    const filterById = async (e) => {
        e.preventDefault();
        await fetch("https://app-mcewan.herokuapp.com/timebilling/getUser/" + id, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "token": user.tokenTimeBilling
            })
        })
            .then(response => response.json())
            .then((value) => {
                const arr = [];
                console.log(value);
                arr.push(value);
                setUsuarios(arr);
            })
            .catch(error => console.log(error));
        setId("");
    }

    return (
        <div className="container_general">
            <img src={fondo} className='fondo' alt='mcewanfondo' />
            <Header />
            <div className="body_container">
                <div className="general-filters">
                    <form>
                        <div className="form-group">
                            <p>Username</p>
                            <div className="input-case">
                                <input type="text" className="search_input"
                                    value={nombre} onChange={(value) => setNombre(value.target.value)}
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <p>Solo Activos</p>
                            <div className="input-case">
                                <input type="checkbox" className="search_input"
                                    value={activo} onChange={(value) => setActivo(value.target.checked)}
                                />
                            </div>
                        </div>

                    </form>
                </div>
                <div className="filters_container">
                    <form className="search_container" onSubmit={filterById}>
                        <input type="text" placeholder="Ingrese la id del usuario" className="search_input"
                            value={id} onChange={(value) => setId(value.target.value)}
                        />
                        <button className="search_button">
                            <img src={search} className="search_img" />
                        </button>
                    </form>
                    <button className={`return_button ${Usuarios.length === 1 ? "" : "no_display"}`} onClick={getUsuarios}>
                        Volver
                    </button>
                </div>
                <div style={{ width: 'auto' }} className="table_card">
                    <table>
                        <tr>
                            <th>ID</th>
                            <th>Activo</th>
                            <th>Nombre</th>
                            <th>Username</th>
                            <th>E-mail</th>
                            <th>Categoria ID</th>

                        </tr>
                        {
                            Usuarios.filter((val) => {
                                if (nombre === "") {
                                    return val;
                                } else if (val.name.toLowerCase().includes(nombre.toLowerCase())) {
                                    return val;
                                }

                            }
                            ).filter((val) => {
                                if (activo === false) {
                                    return val;
                                } else if (val.active === "1" && activo===true) {
                                    return val;
                                }
                            }).map((value, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{value.id}</td>
                                        <td>{value.active}</td>
                                        <td>{value.name}</td>
                                        <td>{value.username}</td>
                                        <td>{value.email}</td>
                                        <td>{value.user_category_id}</td>

                                    </tr>
                                );
                            })
                        }
                    </table>
                </div>
            </div>
        </div>
    );
}

export default ViewUsers;