import React,{useState} from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  ArcElement,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { ResponsiveContainer } from "recharts";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function PieGraph() {
  const data = {
   labels:[
    'facturado',
   'no facturado',
   'negro'],
   datasets:[{
    label: 'Facturación',
    data: [65, 59, 70],
    backgroundColor: [
        '#d42e12',
        '#004557',
        '#6d6d8d',
      ],
      hoverOffset: 4
   }]
  }; 
    return (
        <div className="graph-overlay">
            <div className="top-part">
                <p>INFORME X2</p>
            </div>
            <div className="bottom-part">
            <Pie data={data}
            options={{
              responsive:true
            }}/>
            </div>

        </div>
    );
}

export default PieGraph;